@use "../../../sass/abstracts/variables";
.breakdown {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.table-wrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 1px #dee2e6;
  border-top-color: transparent !important;
}
.left-table-header {
  border-top-left-radius: 20px;
}
.left-table-header-0 {
  @extend .left-table-header;
  background-color: variables.$plan-primary-color !important;
}
.left-table-header-1 {
  @extend .left-table-header;
  background-color: variables.$implement-primary-color !important;
}
.left-table-header-2 {
  @extend .left-table-header;
  background-color: variables.$measure-primary-color !important;
}
.left-table-header-3 {
  @extend .left-table-header;
  background-color: variables.$report-primary-color !important;
}

.table-body-0 {
  background-color: variables.$plan-secondary-color !important;
}
.table-body-1 {
  background-color: variables.$implement-secondary-color !important;
}
.table-body-2 {
  background-color: variables.$measure-secondary-color !important;
}
.table-body-3 {
  background-color: variables.$report-secondary-color !important;
}

.section-result-cell-limited {
  background-color: variables.$thoughtful-primary-color !important;
  color: white !important;
}
.section-result-cell-developing {
  background-color: variables.$purposeful-primary-color !important;
}
.section-result-cell-advanced {
  background-color: variables.$strategic-primary-color !important;
  color: white !important;
}
.section-result-cell-leading {
  background-color: variables.$transformational-primary-color !important;
  color: white !important;
}

.sub-section-result-cell-limited {
  background-color: variables.$thoughtful-secondary-color !important;
}
.sub-section-result-cell-developing {
  background-color: variables.$purposeful-secondary-color !important;
}
.sub-section-result-cell-advanced {
  background-color: variables.$strategic-secondary-color !important;
}
.sub-section-result-cell-leading {
  background-color: variables.$transformational-secondary-color !important;
}

.right-table-header {
  border-top-right-radius: 20px;
}
