.small-input {
  font-size: 14px;
}

.custom-table .sticky-top {
  top: 60px;
}

.admin-dashboard .text-red {
  color: #e51935 !important;
}

.admin-dashboard .insight-section .is-invalid {
  background-image: none;
  padding-right: 0.75rem;
}

.admin-dashboard .insight-title {
  text-decoration: underline;
  margin-top: 0;
  margin-bottom: 2rem;
  color: #2f5496;
}
