.text-blue {
    color: rgb(47,84,150) !important;
}
.overall-maturity-border {
    border: 1px solid #000000;
    border-radius: 10px;
}
.fixed-table{
    table-layout: fixed;
}

.custom-legend{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.custom-legend > div {
    padding: 5px 0px;
    width: 120px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: rgb(87,87,86);
}

.button-tooltip-wrapper {
    position: absolute;
    top:-15px;
    right:-14px;
}
.spider-graph-wrapper{
    width: 100vw;
    position: relative;
}
@media only screen and (min-width: 650px) {
    .spider-graph-wrapper{
        width: 650px;
        position: relative;
    }
    .custom-legend > div {
        padding: 7px 0px;
        width: 150px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: rgb(87,87,86);
    }
}