
.overall-result-logo {
    position: relative;
    display: inline-block;
}

.overall-result {
    position: relative;
    left: 15px;
}
.overlay-tint {
    filter: brightness(70%);
    max-height: 100px;
}
.highlighted-result{
    max-height: 150px;
}
.overall-result-logo:nth-child(1){
    z-index: 4;
}
.overall-result-logo:nth-child(2){
    z-index: 3;
    left: -10px;
}
.overall-result-logo:nth-child(3){
    z-index: 2;
    left: -20px;
}
.overall-result-logo:nth-child(4){
    z-index: 1;
    left: -30px;
}



@media only screen and (min-width: 768px) {
    .overall-result {
        position: relative;
        left: 30px;
    }
    .overlay-tint {
        filter: brightness(70%);
        max-height: 150px;
    }
    .highlighted-result{
        max-height: 200px;
    }
    .overall-result-logo:nth-child(1){
        z-index: 4;
    }
    .overall-result-logo:nth-child(2){
        z-index: 3;
        left: -20px;
    }
    .overall-result-logo:nth-child(3){
        z-index: 2;
        left: -40px;
    }
    .overall-result-logo:nth-child(4){
        z-index: 1;
        left: -60px;
    }
}


@media only screen and (min-width: 992px) {
    .overall-result {
        position: relative;
        left: 37.5px;
    }
    .overlay-tint {
        filter: brightness(70%);
        max-height: 180px;
    }
    .highlighted-result{
        max-height: 260px;
    }
    .overall-result-logo:nth-child(1){
        z-index: 4;
    }
    .overall-result-logo:nth-child(2){
        z-index: 3;
        left: -25px;
    }
    .overall-result-logo:nth-child(3){
        z-index: 2;
        left: -50px;
    }
    .overall-result-logo:nth-child(4){
        z-index: 1;
        left: -75px;
    }
}

