.text-blue {
  color: rgb(47, 84, 150) !important;
}
.overall-maturity-border {
  border: 1px solid #000000;
  border-radius: 10px;
}
.fixed-table {
  table-layout: fixed;
}

.custom-table-2 th,
.custom-table-2 td {
  font-size: 10pt !important;
}

.overall-result-logo-report {
  position: relative;
  display: inline-block;
  height: 100px;
}

.overall-result-report {
  position: relative;
  left: 21px;
}
.overlay-tint-report {
  filter: brightness(70%);
  max-height: 150px;
}
.highlighted-result-report {
  height: 150px;
}
.overall-result-logo-report:nth-child(1) {
  z-index: 4;
}
.overall-result-logo-report:nth-child(2) {
  z-index: 3;
  left: -14px;
}
.overall-result-logo-report:nth-child(3) {
  z-index: 2;
  left: -28px;
}
.overall-result-logo-report:nth-child(4) {
  z-index: 1;
  left: -42px;
}

.result {
  font-size: 11pt;
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: bold;
  font-style: italic;
}
.root-anchor {
  position: absolute;
  bottom: 0;
}
.result-section {
  font-size: 10pt;
  height: 65px;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #ffffff;
  text-transform: uppercase;
}
.result-section > span {
  font-weight: bold;
}


.spider-graph-summary-wrapper {
  margin-top: -30px;
  width: 475px;
  height: 475px;
  position: relative;
}
.custom-legend-summary{
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
}
.custom-legend-summary > div {
  padding: 6px 0px;
  width: 100px;
  text-align: center;
  font-size: 8pt;
  font-weight: bold;
  color: rgb(87,87,86);
}