.sticky-table-header{
    position: sticky;
    top: 59px;
}
.horizontal-scroll-table {
    display: block;
    overflow-x:hidden;
}
.horizontal-scroll-table-content {
    display: table;
    width: 100%;
    min-width: 1900px;
    table-layout: fixed;
}
.guidance-column {
    white-space: break-spaces;
}