.nav-item:hover > .nav-link > .menu-arrow {
    color: #4d83ff !important;
}
.nav-item > .dropdown-menu > .dropdown-item {
    font-size: 0.875rem !important;
    padding: .65rem 1.5rem !important;
}

.nav-item > .dropdown-menu {
    transform: translate(0px, 49px) !important;
    border-radius: 0px;
    animation-name: dropdownAnimation;
    -webkit-animation-duration: 0.25s;
    -moz-animation-duration: 0.25s;
    -ms-animation-duration: 0.25s;
    -o-animation-duration: 0.25s;
    animation-duration: 0.25s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}
@keyframes dropdownAnimation {
    from {
      opacity: 0;
      transform: translate3d(0, 0px, 0);
    }
    to {
      opacity: 1;
      transform: none;
      transform: translate3d(0, 49px, 0);
    }
  }