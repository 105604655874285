.rounded-modal .modal-content {
  border-radius: 16px;
  border: none;
}

.rounded-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rounded-modal-body > h5 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: bold;
}

.rounded-modal-body > p {
  font-size: 14px;
}

.rounded-modal-success {
  color: rgb(36, 185, 7);
}

.rounded-modal-error {
  color: rgb(237, 41, 57);
}

.rounded-modal-warning {
  color: rgb(248, 148, 6);
}

.rounded-modal-bg-success {
  background-color: rgb(36, 185, 7);
}

.rounded-modal-bg-error {
  background-color: rgb(237, 41, 57);
}

.rounded-modal-bg-warning {
  background-color: rgb(248, 148, 6);
}

.rounded-modal-footer {
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-bottom: -1px;
  margin-right: -1px;
}
