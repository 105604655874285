@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic"), url(./pages/styles/branding/b4siConstants/fonts/century-gothic/GOTHIC.TTF) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Century Gothic";
  src: local("Century Gothic"), url(./pages/styles/branding/b4siConstants/fonts/century-gothic/GOTHICB0.TTF) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Omnes";
  src: local("Omnes"), url(./pages/styles/branding/b4siConstants/fonts/omnes/omnes-regular-webfont.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Omnes";
  src: local("Omnes"), url(./pages/styles/branding/b4siConstants/fonts/omnes/omnes-semibold-webfont.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
