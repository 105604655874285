.header {
  height: 0.84in;
  background-color: #17a2b8;
  position: relative;
}
.left-logo {
  height: 0.3125in;
  position: absolute;
  margin-left: 0.25in;
  top: 50%;
  transform: translateY(-50%);
}
.right-logo {
  height: 0.625in;
  position: absolute;
  margin-right: 0.25in;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
