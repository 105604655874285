.table-header-filter {
    border:none;
    background-color: transparent !important;
    color: inherit !important;
    font-weight: inherit;
    white-space: normal;
}
.table-header-filter:focus {
    box-shadow: none !important;
}
