.sub-section-name {
  background-color: rgb(217, 217, 217);
}
.survey-page-report .border-top-none {
  border-top: none;
}
.survey-page-report .w-10 {
  width: 10%;
}
.survey-page-report .w-25 {
  width: 25%;
}

.survey-page-report .comment {
  padding-top: 0.25rem;
}

.survey-page-report {
  font-family: "Roboto", sans-serif !important;
}

.survey-page-report .fs-1rem {
  font-size: 1rem;
  font-weight: 600;
}


.survey-page-report .border-none {
  border: none;
}

.survey-page-report .bg-header-question {
  background-color: #e9ecef;
}

.survey-page-report table tr,
.survey-page-report table tr td {
  page-break-inside: avoid;
}

@media print {
  .survey-page-report .page-break-after-auto {
    page-break-after: auto;
  }
}

.survey-page-report .wrapper::before {
  content: "";
  display: block;
  height: 125px;
  margin-bottom: -125px;
  page-break-inside: avoid;
  break-inside: avoid;
}
.survey-page-report .wrapper::after {
  display: none;
}

.survey-page-report .wrapper-section::before {
  content: "";
  display: block;
  height: 125px;
  margin-bottom: -125px;
  page-break-inside: avoid;
  break-inside: avoid;
}
.survey-page-report .wrapper-section::after {
  display: none;
}

.survey-page-report td {
  font-size: 10pt !important;
}
