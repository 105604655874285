.carousel-progress-bar {
    display: flex;
    /* flex-flow: row wrap; */
    flex-direction: row;
    flex-wrap: wrap;
}
.carousel-progress-bar .carousel-inner {
    order: 4;
    width: auto;
    flex-grow:1;
}
.carousel-progress-bar .carousel-control-prev {
    position: relative;
    order: 3;
    width:auto;
}
  
.carousel-progress-bar .carousel-control-next {
    position: relative;
    order: 5;
    width:auto;
}
.carousel-progress-bar .carousel-indicators {
    position: relative;
    order: 1;
    flex-grow:1;
    margin-bottom: 0;
}
.carousel-progress-bar .carousel-indicators > button {
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
}
.carousel-progress-bar::after {
    content: '';
    width: 100%;
    order: 2;
}