.collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
/* Icons */
.icons-list {
    border-left: 1px solid #f3f3f3;
    border-top: 1px solid #f3f3f3;
  }
  
  .icons-list > div {
    background: #ffffff;
    border-bottom: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px 15px;
    font-weight: 400;
    font-size: 0.875rem;
  }
  
  .icons-list > div i {
    display: inline-block;
    font-size: 20px;
    width: 40px;
    text-align: left;
    color: #4d83ff;
  }
  
  .icon-in-bg {
    width: 55px;
    height: 55px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  
  .icon-lg {
    font-size: 2.5rem;
  }
  
  .icon-md {
    font-size: 1.875rem;
  }
  
  .icon-sm {
    font-size: 1rem;
  }
  
  /*icon boxes*/
  .icon-box-primary {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #4d83ff;
  }
  
  .icon-box-primary i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-primary.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-primary.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-secondary {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #686868;
  }
  
  .icon-box-secondary i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-secondary.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-secondary.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-success {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #71c016;
  }
  
  .icon-box-success i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-success.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-success.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-info {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #7859df;
  }
  
  .icon-box-info i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-info.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-info.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-warning {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #ffc100;
  }
  
  .icon-box-warning i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-warning.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-warning.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-danger {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #ff4747;
  }
  
  .icon-box-danger i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-danger.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-danger.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-light {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #f8f9fa;
  }
  
  .icon-box-light i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-light.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-light.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  .icon-box-dark {
    width: 2.812rem;
    height: 2.812rem;
    border-radius: 5px;
    line-height: 2.812rem;
    text-align: center;
    background: #282f3a;
  }
  
  .icon-box-dark i {
    color: white;
    font-size: 1.25rem;
  }
  
  .icon-box-dark.icon-box-lg {
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
  }
  
  .icon-box-dark.icon-box-lg i {
    font-size: 2.5rem;
  }
  
  /* Lists */
  ul,
  ol,
  dl {
    padding-left: 1rem;
    font-size: 0.875rem;
  }
  
  ul li,
  ol li,
  dl li {
    line-height: 1.8;
  }
  
  .list-ticked,
  .list-arrow,
  .list-star {
    list-style: none;
    padding: 0;
  }
  
  .list-ticked li,
  .list-arrow li,
  .list-star li {
    padding-left: 1.5rem;
  }
  
  .list-ticked li:before,
  .list-arrow li:before,
  .list-star li:before {
    font-family: "Material Design Icons";
    margin-left: -1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .list-ticked li:before {
    content: "\F12D";
    color: #ff4747;
  }
  
  .list-arrow li:before {
    content: "\F142";
    color: #71c016;
  }
  
  .list-star li:before {
    content: "\F4CE";
    color: #ffc100;
  }
  
  .solid-bullet-list {
    position: relative;
    padding-left: 0;
  }
  
  .rtl .solid-bullet-list {
    padding-right: 0;
  }
  
  .solid-bullet-list li {
    position: relative;
    list-style-type: none;
    padding-left: 25px;
    line-height: 1;
    padding-bottom: 2.125rem;
  }
  
  .solid-bullet-list li * {
    line-height: 0.8;
  }
  
  .solid-bullet-list li:before,
  .solid-bullet-list li:after {
    content: "";
    position: absolute;
  }
  
  .solid-bullet-list li:before {
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #4d83ff;
    z-index: 1;
  }
  
  .solid-bullet-list:after {
    content: "";
    border: 1px solid #f3f3f3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4px;
    z-index: 0;
  }
  
  .bullet-line-list {
    padding-left: 30px;
    position: relative;
    list-style-type: none;
  }
  
  .rtl .bullet-line-list {
    padding-left: unset;
    padding-right: 30px;
  }
  
  .bullet-line-list li {
    position: relative;
  }
  
  .bullet-line-list li:before {
    width: 15px;
    height: 15px;
    left: -30px;
    top: 0;
    border: 4px solid #4d83ff;
    margin-right: 15px;
    z-index: 2;
    background: #ffffff;
    content: "";
    position: absolute;
    border-radius: 100%;
  }
  
  .rtl .bullet-line-list li:before {
    left: unset;
    right: -45px;
  }
  
  .bullet-line-list:after {
    content: "";
    border: 1px solid #f3f3f3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 7px;
  }
  
  .rtl .bullet-line-list:after {
    left: unset;
    right: 7px;
  }
  
  /* Tables */
    
  .table thead th {
    font-weight: 500;
    font-size: 1rem;
  }
  .table th,
  .table td {
    vertical-align: middle;
    line-height: 1;
  }
  .table td {
    font-size: 0.875rem;
  }
  /*-------------------------------------------------------------------*/
  /* === Plugin overrides === */
  /* Data Tables */
  .dataTables_wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  
  .dataTables_wrapper label {
    font-size: 0.8125rem;
  }
  
  .dataTables_wrapper select {
    padding: 0.4rem;
    outline-offset: -2px;
  }
  
  .dataTables_wrapper .dataTables_length select {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  
  .dataTables_wrapper .dataTable {
    border-collapse: collapse !important;
  }
  
  .dataTables_wrapper .dataTable .btn {
    padding: 0.1rem 1rem;
    vertical-align: top;
  }
  
  .dataTables_wrapper .dataTable thead th {
    border: 1px solid rgba(151, 151, 151, 0.18);
    padding: 1.7rem 0.9375rem;
    white-space: nowrap;
  }
  
  .dataTables_wrapper .dataTable thead .sorting:before,
  .dataTables_wrapper .dataTable thead .sorting:after,
  .dataTables_wrapper .dataTable thead .sorting_asc:before,
  .dataTables_wrapper .dataTable thead .sorting_asc:after,
  .dataTables_wrapper .dataTable thead .sorting_desc:before,
  .dataTables_wrapper .dataTable thead .sorting_desc:after,
  .dataTables_wrapper .dataTable thead .sorting_asc_disabled:before,
  .dataTables_wrapper .dataTable thead .sorting_asc_disabled:after,
  .dataTables_wrapper .dataTable thead .sorting_desc_disabled:before,
  .dataTables_wrapper .dataTable thead .sorting_desc_disabled:after {
    line-height: 2.5;
    font-family: Material Design Icons;
    font-size: 0.65rem;
  }
  
  .dataTables_wrapper .dataTable thead .sorting:before,
  .dataTables_wrapper .dataTable thead .sorting_asc:before,
  .dataTables_wrapper .dataTable thead .sorting_desc:before,
  .dataTables_wrapper .dataTable thead .sorting_asc_disabled:before,
  .dataTables_wrapper .dataTable thead .sorting_desc_disabled:before {
    content: "\F05D";
    right: 2.4em;
    bottom: 1.5rem;
  }
  
  .dataTables_wrapper .dataTable thead .sorting:after,
  .dataTables_wrapper .dataTable thead .sorting_asc:after,
  .dataTables_wrapper .dataTable thead .sorting_desc:after,
  .dataTables_wrapper .dataTable thead .sorting_asc_disabled:after,
  .dataTables_wrapper .dataTable thead .sorting_desc_disabled:after {
    content: "\F045";
    right: 2em;
    bottom: 1.1rem;
  }
  
  .dataTables_wrapper .dataTable tbody tr td {
    border: 1px solid rgba(151, 151, 151, 0.18);
    color: #08113b;
    white-space: nowrap;
    font-weight: 300;
    padding: 1.1rem 0.9375rem;
  }
  
  .dataTables_wrapper .dataTable tbody tr:nth-child(odd) {
    background: rgba(238, 238, 238, 0.57);
  }
  
  .dataTables_wrapper .dataTables_paginate {
    margin-top: 20px;
  }
  
  .dataTables_wrapper .dataTables_info {
    font-size: 0.875rem;
  }
  
  @media (max-width: 576px) {
    .dataTables_wrapper .dataTables_info {
      margin-bottom: 1rem;
    }
  }
  
  /*-------------------------------------------------------------------*/
  /* === Landing screens === */
  /* Auth */
  /* .auth .login-half-bg {
    background: url("../images/auth/login-bg.jpg");
    background-size: cover;
  }
  
  .auth .register-half-bg {
    background: url("../images/auth/register-bg.jpg");
    background-size: cover;
  }
  
  .auth.lock-full-bg {
    background: url("../images/auth/lockscreen-bg.jpg");
    background-size: cover;
  } */
  
  .auth .lock-profile-img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
  }
  
  .auth .auth-form-light {
    background: #ffffff;
  }
  
  .auth .auth-form-light select {
    color: #c9c8c8;
  }
  
  .auth .auth-form-light .input-group .form-control:focus,
  .auth .auth-form-light .input-group .dataTables_wrapper select:focus,
  .dataTables_wrapper .auth .auth-form-light .input-group select:focus,
  .auth .auth-form-light .input-group .form-control:active,
  .auth .auth-form-light .input-group .dataTables_wrapper select:active,
  .dataTables_wrapper .auth .auth-form-light .input-group select:active {
    border-color: #f3f3f3;
  }
  
  .auth .auth-form-transparent {
    background: transparent;
  }
  
  .auth .auth-form-transparent .form-control,
  .auth .auth-form-transparent .dataTables_wrapper select,
  .dataTables_wrapper .auth .auth-form-transparent select,
  .auth .auth-form-transparent .input-group-text {
    border-color: #686868;
  }
  
  .auth .auth-form-transparent .form-control:focus,
  .auth .auth-form-transparent .dataTables_wrapper select:focus,
  .dataTables_wrapper .auth .auth-form-transparent select:focus,
  .auth .auth-form-transparent .form-control:active,
  .auth .auth-form-transparent .dataTables_wrapper select:active,
  .dataTables_wrapper .auth .auth-form-transparent select:active,
  .auth .auth-form-transparent .input-group-text:focus,
  .auth .auth-form-transparent .input-group-text:active {
    border-color: #686868;
  }
  
  .auth .auth-form-transparent select {
    outline-color: #686868;
  }
  
  .auth.auth-img-bg {
    padding: 0;
  }
  
  @media (min-width: 768px) {
    .auth.auth-img-bg .auth-form-transparent {
      width: 55%;
      margin: auto;
    }
  }
  
  .auth .brand-logo {
    margin-bottom: 2rem;
  }
  
  .auth .brand-logo img {
    width: 150px;
  }
  
  .auth form .form-group {
    margin-bottom: 1.5rem;
  }
  
  .auth form .form-group label {
    font-size: 0.8125rem;
  }
  
  .auth form .form-group .form-control,
  .auth form .form-group .dataTables_wrapper select,
  .dataTables_wrapper .auth form .form-group select {
    background: transparent;
    border-radius: 0;
    font-size: 0.9375rem;
  }
  
  .auth form .auth-form-btn {
    height: 50px;
    line-height: 1.5;
  }
  
  .auth form .auth-link {
    font-size: 0.875rem;
  }
  
  .auth form .auth-link:hover {
    color: initial;
  }
  
  .page-body-wrapper {
    min-height: calc(100vh - 60px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    padding-top: 60px;
  }
  
  .page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
  }
  
  .main-panel {
    transition: width 0.25s ease, margin 0.25s ease;
    width: calc(100% - 257px);
    min-height: calc(100vh - 60px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  
  @media (max-width: 991px) {
    .main-panel {
      margin-left: 0;
      width: 100%;
    }
  }
  
  .content-wrapper {
    background: #f3f3f3;
    padding: 2.1rem 1.04rem;
    width: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
  
  @media (max-width: 767px) {
    .content-wrapper {
      padding: 1.5rem 1.5rem;
    }
  }
  
  /* Sidebar */
  .sidebar {
    min-height: calc(100vh - 60px);
    background: #ffffff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding: 0;
    width: 257px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    border-right: 1px solid #e3e3e3;
  }
  
  .sidebar .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
  }
  
  .sidebar .nav .nav-item {
    -webkit-transition-duration: 0.25s;
    -moz-transition-duration: 0.25s;
    -o-transition-duration: 0.25s;
    transition-duration: 0.25s;
    transition-property: background;
    -webkit-transition-property: background;
  }
  
  .sidebar .nav .nav-item .collapse {
    z-index: 999;
  }
  
  .sidebar .nav .nav-item .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    white-space: nowrap;
    padding: 0.75rem 2.5rem 0.75rem 1.25rem;
    color: #000;
    -webkit-transition-duration: 0.45s;
    -moz-transition-duration: 0.45s;
    -o-transition-duration: 0.45s;
    transition-duration: 0.45s;
    transition-property: color;
    -webkit-transition-property: color;
  }
  
  .sidebar .nav .nav-item .nav-link i {
    color: inherit;
  }
  
  .sidebar .nav .nav-item .nav-link i.menu-icon {
    font-size: 1.125rem;
    line-height: 1;
    margin-right: 1.125rem;
    color: inherit;
  }
  
  .rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-left: 2rem;
    margin-right: 0;
  }
  
  .sidebar .nav .nav-item .nav-link i.menu-icon:before {
    vertical-align: middle;
  }
  
  .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: auto;
    margin-right: 0;
    color: #000;
  }
  
  .rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
    margin-left: 0;
    margin-right: auto;
  }
  
  .sidebar .nav .nav-item .nav-link i.menu-arrow:before {
    content: "\F140";
    font-family: "Material Design Icons";
    font-style: normal;
    display: block;
    font-size: 1rem;
    line-height: 10px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  
  .sidebar .nav .nav-item .nav-link .menu-title {
    color: inherit;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1;
    vertical-align: middle;
  }
  
  .sidebar .nav .nav-item .nav-link .badge {
    margin-left: auto;
  }
  
  .rtl .sidebar .nav .nav-item .nav-link .badge {
    margin-left: 0;
    margin-right: auto;
  }
  
  .sidebar .nav .nav-item .nav-link[aria-expanded="true"] i.menu-arrow:before {
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .sidebar .nav .nav-item.active > .nav-link {
    background: initial;
    position: relative;
  }
  
  .sidebar .nav .nav-item.active > .nav-link i,
  .sidebar .nav .nav-item.active > .nav-link .menu-title,
  .sidebar .nav .nav-item.active > .nav-link .menu-arrow {
    color: #4d83ff;
  }
  
  .sidebar .nav:not(.sub-menu) {
    margin-top: 0.3rem;
  }
  
  .sidebar .nav:not(.sub-menu) > .nav-item {
    border-bottom: 1px solid #f3f3f3;
    margin-top: 0;
  }
  
  .sidebar .nav:not(.sub-menu) > .nav-item > .nav-link {
    margin: 0;
  }
  
  .sidebar .nav:not(.sub-menu) > .nav-item:hover > .nav-link {
    background: transparent;
    color: #4d83ff;
  }
  
  .sidebar .nav.sub-menu {
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
    padding: 0.25rem 0 0.6rem 3.55rem;
  }
  
  .sidebar .nav.sub-menu .nav-item {
    padding: 0;
  }
  
  .sidebar .nav.sub-menu .nav-item::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
    margin-top: 15px;
  }
  
  .sidebar .nav.sub-menu .nav-item .nav-link {
    color: #656565;
    padding: 0.6rem 1rem;
    position: relative;
    font-size: 0.875rem;
    line-height: 1;
    height: auto;
    border-top: 0;
  }
  
  .sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: #000;
  }
  
  .sidebar .nav.sub-menu .nav-item .nav-link.active {
    color: #000;
    background: transparent;
  }
  
  .sidebar .nav.sub-menu .nav-item:hover {
    background: transparent;
  }
  
  /* style for off-canvas menu*/
  @media screen and (max-width: 991px) {
    .sidebar-offcanvas {
      position: fixed;
      max-height: calc(100vh - 60px);
      top: 60px;
      bottom: 0;
      overflow: auto;
      right: -257px;
      -webkit-transition: all 0.25s ease-out;
      -o-transition: all 0.25s ease-out;
      transition: all 0.25s ease-out;
    }
    .sidebar-offcanvas.active {
      right: 0;
    }
  }
  
  /* Navbar */
  .navbar {
    font-weight: 400;
    transition: background 0.25s ease;
    -webkit-transition: background 0.25s ease;
    -moz-transition: background 0.25s ease;
    -ms-transition: background 0.25s ease;
  }
  
  .navbar .navbar-brand-wrapper {
    background: #ffffff;
    border-bottom: 1px solid #e3e3e3;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    width: 257px;
    height: 60px;
    border-right: 1px solid #e3e3e3;
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
    color: #27367f;
    font-size: 1.5rem;
    margin-right: 0;
    padding: 0.25rem 0;
  }
  
  .navbar
    .navbar-brand-wrapper
    .navbar-brand-inner-wrapper
    .navbar-brand.brand-logo-mini {
    display: none;
  }
  
  @media (max-width: 991px) {
    .navbar
      .navbar-brand-wrapper
      .navbar-brand-inner-wrapper
      .navbar-brand.brand-logo-mini {
      display: inline-block;
      width: 1.875rem;
    }
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:active,
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:focus,
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:hover {
    color: #1b2658;
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand img {
    /*width: calc(257px - 150px);*/
    max-width: 100%;
    height: 30px;
    margin: auto;
    vertical-align: middle;
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-toggler {
    border: 0;
    color: #4a4a4a;
    font-size: 1.5rem;
    padding: 0;
  }
  
  @media (max-width: 991px) {
    .navbar
      .navbar-brand-wrapper
      .navbar-brand-inner-wrapper
      .navbar-toggler:not(.navbar-toggler-right) {
      display: none;
    }
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini {
    padding-left: 0;
    text-align: center;
  }
  
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini img {
    width: calc(70px - 30px);
    max-width: 100%;
    margin: auto;
  }
  
  .navbar .navbar-menu-wrapper {
    background: #ffffff;
    transition: width 0.25s ease;
    -webkit-transition: width 0.25s ease;
    -moz-transition: width 0.25s ease;
    -ms-transition: width 0.25s ease;
    color: #9b9b9b;
    padding-left: 1.062rem;
    padding-right: 1.062rem;
    width: calc(100% - 257px);
    height: 60px;
    border-bottom: 1px solid #e3e3e3;
  }
  
  @media (max-width: 991px) {
    .navbar .navbar-menu-wrapper {
      width: calc(100% - 55px);
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .navbar .navbar-menu-wrapper .navbar-toggler {
    border: 0;
    color: inherit;
    font-size: 1.5rem;
    padding: 0;
  }
  
  @media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
      padding-left: 15px;
      padding-right: 11px;
    }
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 0.875rem;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search {
    margin-left: 0rem;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group {
    background: #ececec;
    border-radius: 4px;
    padding: 0 0.75rem;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .dataTables_wrapper
    select,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    select,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .input-group-text {
    background: transparent;
    border: 0;
    color: #000;
    padding: 0;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .input-group-text
    i {
    color: #9b9b9b;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .dataTables_wrapper
    select,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    select {
    margin-left: 0.5rem;
    height: 2.5rem;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control::-webkit-input-placeholder,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .dataTables_wrapper
    select::-webkit-input-placeholder,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    select::-webkit-input-placeholder {
    color: #9b9b9b;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control:-moz-placeholder,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .dataTables_wrapper
    select:-moz-placeholder,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    select:-moz-placeholder {
    color: #9b9b9b;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control::-moz-placeholder,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .dataTables_wrapper
    select::-moz-placeholder,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    select::-moz-placeholder {
    color: #9b9b9b;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .form-control:-ms-input-placeholder,
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    .dataTables_wrapper
    select:-ms-input-placeholder,
  .dataTables_wrapper
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-search
    .input-group
    select:-ms-input-placeholder {
    color: #9b9b9b;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
    margin: 0;
    padding-left: 1.5rem;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
    padding: 0;
    text-align: center;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings i {
    font-size: 1.5rem;
    vertical-align: middle;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile {
    margin-left: 1.8rem;
    margin-right: 1.8rem;
    white-space: nowrap;
  }
  
  @media (max-width: 768px) {
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-profile
    .nav-link
    .nav-profile-name {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: #4a4a4a;
    font-weight: 500;
  }
  
  @media (max-width: 767px) {
    .navbar
      .navbar-menu-wrapper
      .navbar-nav
      .nav-item.nav-profile
      .nav-link
      .nav-profile-name {
      display: none;
    }
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.nav-profile
    .nav-link::after {
    color: #4a4a4a;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
    border: none;
    -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    position: absolute;
    font-size: 0.9rem;
    margin-top: 0;
    right: 0;
    left: auto;
    top: 60px;
  }
  
  .rtl
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown {
    right: auto;
    left: 0;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item {
    margin-bottom: 0;
    padding: 0.65rem 1.5rem;
    cursor: pointer;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .item-thumbnail
    img {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .item-thumbnail
    .item-icon {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    color: #ffffff;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .item-thumbnail
    .item-icon
    i {
    font-size: 17px;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .item-content {
    padding-left: 0.937rem;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .badge {
    margin-left: 2.5rem;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .ellipsis {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .rtl
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    i {
    margin-left: 10px;
  }
  
  .rtl
    .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-item
    .badge {
    margin-left: 0;
    margin-right: 2.5rem;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .navbar-dropdown
    .dropdown-divider {
    margin: 0;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
  
  @media (max-width: 991px) {
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
      position: static;
    }
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
      left: 20px;
      right: 20px;
      top: 60px;
      width: calc(100% - 40px);
    }
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator {
    position: relative;
    padding: 0;
    text-align: center;
  }
  
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator i {
    font-size: 1.5rem;
    margin-right: 0;
    vertical-align: middle;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .count-indicator
    .count {
    position: absolute;
    left: 59%;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #ff4747;
    top: 9px;
  }
  
  .navbar
    .navbar-menu-wrapper
    .navbar-nav
    .nav-item.dropdown
    .count-indicator:after {
    display: none;
  }
  
  @media (min-width: 992px) {
    .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
      margin-left: auto;
    }
    .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
      margin-left: 0;
      margin-right: auto;
    }
  }
  
  @media (max-width: 991px) {
    .navbar {
      flex-direction: row;
    }
    .navbar .navbar-brand-wrapper {
      width: 55px;
    }
    .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
      display: none;
    }
    .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
      display: inline-block;
    }
    .navbar-collapse {
      display: flex;
      margin-top: 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .navbar .navbar-brand-wrapper {
      width: 55px;
    }
    .navbar .navbar-brand-wrapper .brand-logo-mini {
      padding-top: 0px;
    }
  }
  
  /* Layouts */
  @media (min-width: 992px) {
    .sidebar-icon-only .navbar .navbar-brand-wrapper {
      width: 70px;
    }
    .sidebar-icon-only .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
      margin-right: auto;
    }
    .sidebar-icon-only
      .navbar
      .navbar-brand-wrapper
      .navbar-brand-inner-wrapper
      .brand-logo {
      display: none;
    }
    .sidebar-icon-only
      .navbar
      .navbar-brand-wrapper
      .navbar-brand-inner-wrapper
      .brand-logo-mini {
      display: none;
    }
    .sidebar-icon-only .navbar .navbar-menu-wrapper {
      width: calc(100% - 70px);
    }
    .sidebar-icon-only .sidebar {
      width: 70px;
    }
    .sidebar-icon-only .sidebar .nav {
      overflow: visible;
    }
    .sidebar-icon-only .sidebar .nav .nav-item {
      position: relative;
    }
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
      display: block;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: center;
      position: static;
    }
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
      display: none;
    }
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
      border-radius: 0 5px 5px 0px;
    }
    .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
      border-radius: 5px 0 0 5px;
    }
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }
    .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
      display: none;
    }
    .sidebar-icon-only
      .sidebar
      .nav
      .nav-item
      .nav-link[aria-expanded]
      .menu-title {
      border-radius: 0 5px 0 0px;
    }
    .rtl.sidebar-icon-only
      .sidebar
      .nav
      .nav-item
      .nav-link[aria-expanded]
      .menu-title {
      border-radius: 5px 0 0 0;
    }
    .sidebar-icon-only .sidebar .nav .nav-item .collapse {
      display: none;
    }
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      background: #ffffff;
      padding: 0.5rem 1.4rem;
      left: 70px;
      position: absolute;
      text-align: left;
      top: 0;
      bottom: 0;
      width: 190px;
      z-index: 1;
      line-height: 1.8;
      box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);
    }
    .rtl.sidebar-icon-only
      .sidebar
      .nav
      .nav-item.hover-open
      .nav-link
      .menu-title {
      left: auto;
      right: 70px;
      text-align: left;
      box-shadow: -4px 0px 7px 0px rgba(182, 185, 189, 0.25);
    }
    .sidebar-icon-only
      .sidebar
      .nav
      .nav-item.hover-open
      .nav-link
      .menu-title:after {
      display: none;
    }
    .sidebar-icon-only
      .sidebar
      .nav
      .nav-item.hover-open
      .nav-link:hover
      .menu-title {
      background: #ffffff;
    }
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
    .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
      display: block;
      padding: 0.5rem 0;
      background: #ffffff;
      border-radius: 0 0 5px 0;
      position: absolute;
      left: 70px;
      width: 190px;
      box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.25);
    }
    .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
    .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
      left: auto;
      right: 70px;
      border-radius: 0 0 0 5px;
      box-shadow: -4px 4px 7px 0px rgba(182, 185, 189, 0.25);
    }
    .sidebar-icon-only .sidebar .nav.sub-menu {
      padding: 0 0 0 1.5rem;
    }
    .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
      text-align: left;
      padding-left: 20px;
    }
    .sidebar-icon-only .main-panel {
      width: calc(100% - 70px);
    }
  }
  
  .rtl {
    direction: rtl;
    text-align: right;
  }
  
  .rtl .sidebar .nav {
    padding-right: 0;
  }
  
  .rtl .sidebar .nav.sub-menu {
    padding: 0 4.5rem 0 0;
  }
  
  .sidebar-icon-only.rtl .sidebar .nav.sub-menu {
    padding-right: 0rem;
  }
  
  .sidebar-icon-only.rtl .sidebar .nav.sub-menu .nav-item .nav-link {
    padding-right: 3rem;
    text-align: right;
  }
  
  .sidebar-icon-only.rtl .sidebar .nav.sub-menu .nav-item .nav-link:before {
    right: 1.75rem;
  }
  
  .rtl .product-chart-wrapper::-webkit-scrollbar,
  .rtl .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar,
  .rtl .table-responsive::-webkit-scrollbar,
  .rtl ul.chats::-webkit-scrollbar {
    width: 0.5em;
  }
  
  .rtl .product-chart-wrapper::-webkit-scrollbar-track,
  .rtl .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar-track,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-track,
  .rtl .table-responsive::-webkit-scrollbar-track,
  .rtl ul.chats::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .rtl .product-chart-wrapper::-webkit-scrollbar-thumb,
  .rtl .tab-content .tab-pane .scroll-wrapper::-webkit-scrollbar-thumb,
  .rtl .sidebar-fixed .nav::-webkit-scrollbar-thumb,
  .rtl .table-responsive::-webkit-scrollbar-thumb,
  .rtl ul.chats::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  #statusFilter {
    height: 2.25rem;
    width: 250px;
    display: inline-block;
    margin-inline-start: 5px;
  }
  #statusFilterLabel {
    margin-inline-start: 25px;
  }
  .pull-left {
    float: left !important;
  }
  .pull-right {
    float: right !important;
    position: relative;
  }
  #list-surveys_info {
    display: flex;
    justify-content: flex-end;
  }
  #list-surveys th,
  #list-surveys td {
    text-align: center;
  }
  div.dataTables_wrapper div.dataTables_filter input {
    width: 350px;
  }
  .section-header {
    color: white;
    background-color: #495057;
  }
  
  .padding-label {
    padding-top: 12px;
  }
  
  .table-heading {
    background-color: #ebebeb;
    color: #695057;
  }
  
  .header-middle {
    display: flex;
    align-items: center;
  }
  .sm-text {
    font-size: 0.875rem !important;
  }
  .col-option {
    flex: 0 0 18.3%;
    max-width: 18.3%;
  }
  .option-text {
    font-size: 0.875rem;
    font-style: italic;
  }
  .required:invalid {
    border: 2px solid red;
  }
  
  .invalid {
    border: 2px solid red !important;
  }
  
  @media (max-width: 767px) {
    .dataTables_info {
      display: none !important;
    }
    .dataTables_paginate {
      margin-top: 2.5rem !important;
    }
  }
  /* Footer */
.footer {
  background: #f3f3f3;
  padding: 30px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer a {
  color: #4d83ff;
  font-size: inherit;
}

@media (max-width: 991px) {
  .footer {
    margin-left: 0;
    width: 100%;
  }
}
