@use "../../sass/abstracts/variables";

.sub-section-header {
  border-radius: 10px;
  > * {
    font-weight: 600;
  }
}

.sub-section-header-plan {
  @extend .sub-section-header;
  background-color: variables.$plan-secondary-color !important;
}
.sub-section-header-implement {
  @extend .sub-section-header;
  background-color: variables.$implement-secondary-color !important;
}
.sub-section-header-measure {
  @extend .sub-section-header;
  background-color: variables.$measure-secondary-color !important;
}
.sub-section-header-report {
  @extend .sub-section-header;
  background-color: variables.$report-secondary-color !important;
}

.sub-section-table {
  border-collapse: collapse;
  border-spacing: 0 0px;
}
