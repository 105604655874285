.modal-contact {
  font-family: "Roboto", sans-serif !important;
}
.modal-contact .modal-content {
  border: none;
}
.modal-contact-header {
  background-color: #23c2f2;
  color: #fff;
}
.modal-contact-header .btn-close {
  margin: 0;
}
.modal-contact .modal-content input,
.modal-contact .modal-content textarea {
  border-radius: 1rem;
  padding-left: 1.5rem;
  font-size: 1rem;
}
.modal-contact .description-question {
  font-size: 1rem;
}
.contact-modal-footer {
  border-top: none;
}
.contact-modal-footer button {
  font-weight: 600;
}
