@use "../../../sass/abstracts/variables";

.section-header {
  color: black;
  border-width: 0px;
  border-radius: 7px !important;

  font-size: 1.15rem;
  font-weight: 600;
}
.section-header-plan {
  @extend .section-header;
  background-color: variables.$plan-primary-color !important;
}
.section-header-implement {
  @extend .section-header;
  background-color: variables.$implement-primary-color !important;
}
.section-header-measure {
  @extend .section-header;
  background-color: variables.$measure-primary-color !important;
}
.section-header-report {
  @extend .section-header;
  background-color: variables.$report-primary-color !important;
}

.sub-section-header {
  border-radius: 7px;
}

.sub-section-header-plan {
  @extend .sub-section-header;
  background-color: variables.$plan-secondary-color !important;
}
.sub-section-header-implement {
  @extend .sub-section-header;
  background-color: variables.$implement-secondary-color !important;
}
.sub-section-header-measure {
  @extend .sub-section-header;
  background-color: variables.$measure-secondary-color !important;
}
.sub-section-header-report {
  @extend .sub-section-header;
  background-color: variables.$report-secondary-color !important;
}

.questions {
  border-top: 5px solid transparent;
  background-color: #e9ecef;
}
