p.normal {
  font-size: 10pt;
  line-height: 108%;
  margin-top: 0.15in;
  margin-bottom: 0.15in;
  direction: ltr;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h4.normal {
  font-weight: 300;
  font-size: 11pt;
  font-style: italic;
  margin-top: 0.03in;
  margin-bottom: 0in;
  direction: ltr;
  color: #2f5496;
  line-height: 108%;
  text-align: left;
  page-break-inside: avoid;
  orphans: 2;
  widows: 2;
}
h6.normal {
  font-weight: 300;
  font-size: 11pt;
  margin-top: 0.03in;
  margin-bottom: 0in;
  direction: ltr;
  color: #1f3763;
  line-height: 108%;
  text-align: left;
  page-break-inside: avoid;
  orphans: 2;
  widows: 2;
}
ul.dashed {
  list-style-type: none;
  margin: 0;
  text-align: justify;
}
ul.dashed > li {
  text-align: justify;
  text-justify: inter-word;
}
ul.dashed > li:before {
  content: "-";
  position: absolute;
  margin-left: -20px;
}
p.page-header {
  font-size: 16pt;
  color: #2f5496;
  margin-bottom: 0.11in;
  text-align: center;
}
p.page-description {
  margin-top: 0.11in;
  margin-bottom: 0.11in;
}
h6.company-header {
  font-size: 10pt;
  color: #fff;
  padding: 0.04in 0.06in;
}
li.dashed {
  font-size: 10pt;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  padding-left: 0.075in;
  margin-left: 0.3in;
  margin-bottom: 0.04in;
  line-height: 130%;
  text-align: justify;
  text-justify: inter-word;
}
li.second-indent {
  margin-top: 0.04in;
  line-height: 108%;
  margin-left: 0.15in;
  font-size: 10pt;
}
.cs-text-blue {
  color: #0563cd;
  text-decoration: underline;
}
.text-orange {
  color: #ed7d31;
}
.page-title {
  font-weight: 500;
}
