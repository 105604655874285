@use "../../sass/abstracts/variables";

.progress-overall {
  & > div {
    background-color: variables.$banner-solid-color !important;
  }
}

.progress-plan {
  & > div {
    background-color: variables.$plan-solid-color !important;
  }
}

.progress-implement {
  & > div {
    background-color: variables.$implement-solid-color !important;
  }
}

.progress-measure {
  & > div {
    background-color: variables.$measure-solid-color !important;
  }
}

.progress-report {
  & > div {
    background-color: variables.$report-solid-color !important;
  }
}
