$banner-solid-color: #17a2b8;

$plan-primary-color: rgba(65, 192, 240, 0.4);
$implement-primary-color: rgba(27, 175, 162, 0.4);
$measure-primary-color: rgba(0, 112, 189, 0.4);
$report-primary-color: rgba(130, 46, 142, 0.4);

$plan-secondary-color: rgba(65, 192, 240, 0.15);
$implement-secondary-color: rgba(27, 175, 162, 0.15);
$measure-secondary-color: rgba(0, 112, 189, 0.15);
$report-secondary-color: rgba(130, 46, 142, 0.15);

$plan-solid-color: rgb(65, 193, 240);
$implement-solid-color: rgb(27, 175, 163);
$measure-solid-color: rgb(0, 113, 189);
$report-solid-color: rgb(131, 46, 142);

$thoughtful-primary-color: #d03232;
$purposeful-primary-color: #ffc000;
$strategic-primary-color: #479b69;
$transformational-primary-color: #0070c0;

$thoughtful-secondary-color: rgba(233, 68, 79, 0.4);
$purposeful-secondary-color: rgba(254, 212, 105, 0.4);
$strategic-secondary-color: rgba(126, 185, 150, 0.4);
$transformational-secondary-color: rgba(77, 155, 211, 0.4);

$plan-tooltip-color: #b3e6f9;
$implement-tooltip-color: #a4dfda;
$measure-tooltip-color: #99c6e5;
$report-tooltip-color: #cdabd2;

