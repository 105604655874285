th {
  vertical-align: top;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  background-color: #17a2b8 !important;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 1040;
}

.header-logo {
  margin-top: 8px;
  margin-left: 30px;
  height: 34px;
}

.survey-page .loading {
  height: 4rem;
  width: 4rem;
  margin: 4rem auto auto 50%;
}

.survey-progress {
  cursor: pointer;
  transition: color 0.2s;
}
.survey-progress:hover {
  color: #0d6efd;
}

.section-header {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.sub-section-header {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  vertical-align: top !important;
}

.survey-score .loading {
  height: 4rem;
  width: 4rem;
  margin: 4rem auto auto 50%;
}

.survey-score table {
  font-size: 0.8rem;
}

.survey-score td.value {
  text-align: center;
  vertical-align: middle;
}

.survey-score .nowrap {
  white-space: nowrap;
}

.survey-pagination .btn-pagination {
  min-width: 4.5rem;
  background-color: #17a2b8;
  color: #fff;
  border: none;
}

.card-header {
  font-size: 1em;
  text-align: left;
}

.subsection .table {
  margin-bottom: 0px !important;
}

/*Migrated CSS. Needs Refactoring*/
.table-gray > tr > th {
  background-color: rgb(242, 242, 242);
  color: #695057;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.custom-table {
  border-collapse: separate !important;
  border-spacing: 0px;
}

.question-table {
  display: block;
}

.value-column {
  text-align: center !important;
  width: 3%;
}


.bg-info {
  background-color: #17a2b8 !important;
}

.bg-label {
  background-color: #ebebeb;
  color: #695057;
}

.bg-title {
  background-color: #495057;
}

.justify-content-center {
  justify-content: center;
}

.start_page {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
}

.gray-border {
  border: 1px solid rgb(66, 66, 66) !important;
}

.gray-border.is-invalid {
  border: 1px solid #dc3545 !important;
}

.option-wrapper {
  width: 150px !important;
}

.custom-dropdown {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: white !important;
  color: #000000 !important;
}

.custom-dropdown::after {
  margin-left: auto !important;
}

.dropdown-item:active {
  background-color: #343a40 !important;
  color: white;
}

.icon-button {
  cursor: pointer;
}

.status-text {
  font-size: 14px;
}

.bg-nav {
  background-color: rgb(23, 162, 184);
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  font-style: italic;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-style: italic;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-style: italic;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-style: italic;
}

::placeholder {
  /* Most modern browsers support this now. */
  font-style: italic;
}

.table-actions {
  font-size: 14px !important;
}

.text-muted {
  color: #686868 !important;
}

.none-border-radius {
  border-radius: 0%;
}

/*Login CSS*/
.login-form {
  width: 550px;
}

.create-survey-link-modal .modal-none-border,
.submit-success-modal .modal-none-border {
  border: none;
}
.submit-success-modal-custom-width {
  width: 575px ;
  max-width: none!important;
}
.create-survey-link-modal .icon-success {
  border: solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
  color: #a5dc86;
}

.btn-color-purple {
  border: solid 1px #7066e0;
  background: #7066e0;
}

.btn-color-purple:hover,
.btn-color-purple:focus {
  opacity: 0.9;
  box-shadow: #000000;
  border: solid 1px #7066e0;
  background: #7066e0;
}

.logo-top-right {
  position: absolute;
  top: 4.25rem;
  right: 0.25rem;
}

.insight-section {
  padding: 1.25rem 0 !important;
}

.placeholder-italic::placeholder {
  font-style: italic;
}

.button-icon-sorting-table {
  border: none;
  color: #686868 !important;
  background-color: #fff;
  border-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
}

.button-icon-sorting-table:hover,
.button-icon-sorting-table:focus,
.button-icon-sorting-table:active {
  color: #000 !important;
  background-color: #fff;
  border-color: #fff !important;
  border: none;
  box-shadow: none;
}

@media only screen and (max-width: 1250px) {
  .logo-top-right {
    position: relative !important;
    top: 0;
    right: 0;
  }
}

.border-left-right {
  border-right: 2px solid #fff !important;
  border-left: 2px solid #fff !important;
}

.survey-progress-bar .progress-reminder {
  font-size: 14px;
  color: #c00000;
  font-style: italic;
  font-weight: 500;
  /* position: absolute;
  bottom: 0; */
}

/* responsive for survey page */
@media only screen and (max-width: 1590px) {
  .survey-progress-bar .col .title {
    font-size: 50%;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
  .survey-progress-bar .col .title {
    font-size: 45%;
  }
}

@media only screen and (max-width: 1024px) {
  .survey-progress-bar .col .title {
    font-size: 30%;
  }

  .survey-progress-bar .progress-reminder {
    font-size: 12px;
  }

  .survey-page .section-header {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .survey-page .section-header .context-header {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 912px) {
  .survey-progress-bar .col .title {
    font-size: 40%;
  }
}

.survey-progress-bar .border-left {
  border-left: 2px solid #000;
}

.form-control,
.form-select {
  border-color: #ebebeb;
}

.icon-export {
  width: 120px;
  height: 120px;
}

.icon-export-success {
  border: solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
  color: #a5dc86;
}

.icon-export-loading {
  color: #1d9bf0;
}
