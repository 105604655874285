.answer-error {
  padding: 0.75em 0 !important;
  background-color: #fcdad4 !important;
  border-top: 2px solid #f79d8c;
  border-bottom: 2px solid #f79d8c !important;
}

.answer-error.answer-error-left {
  border-left: 2px solid #f79d8c;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.answer-error.answer-error-right {
  border-right: 2px solid #f79d8c;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.priority-error {
  background-color: #fcdad4 !important;
  border: 2px solid #f79d8c;
}

.priority-error > option {
  background-color: white;
}
