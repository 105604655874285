@use "../../../sass/abstracts/variables";
.purposeful {
  background-color: variables.$purposeful-primary-color !important;
  color: black !important;
}
.thoughtful {
  background-color: variables.$thoughtful-primary-color !important;
  color: white !important;
}
.strategic {
  background-color: variables.$strategic-primary-color !important;
  color: white !important;
}
.transformational {
  background-color: variables.$transformational-primary-color !important;
  color: white !important;
}
