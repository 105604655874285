@use "../../../sass/abstracts/variables";

.info-icon {
  border: 1px solid #495057;
  border-radius: 50%;
}

%info-tooltip-inner {
  text-align: left;
  max-width: 37rem;
  color: #000;
  white-space: pre-line;
  box-shadow: 1px 1px 3px 3px rgb(0 0 0 / 0.2);
}

@mixin info-tooltip($color) {
  opacity: 1 !important;

  & > div:last-child {
    @extend %info-tooltip-inner;
    background-color: $color;
  }
}

@mixin direction-arrow($color) {
  &[data-popper-placement="right"] {
    & > div:first-child {
      &::before {
        border-right-color: $color;
      }
    }
  }

  &[data-popper-placement="left"] {
    & > div:first-child {
      &::before {
        border-left-color: $color;
      }
    }
  }

  &[data-popper-placement="bottom"] {
    & > div:first-child {
      &::before {
        border-bottom-color: $color;
      }
    }
  }

  &[data-popper-placement="top"] {
    & > div:first-child {
      &::before {
        border-top-color: $color;
      }
    }
  }
}

.info-tooltip-default {
  opacity: 1 !important;

  & > div:last-child {
    @extend %info-tooltip-inner;
    color: #fff;
  }
}

.info-tooltip-plan {
  $color: variables.$plan-tooltip-color;
  @include info-tooltip($color);
  @include direction-arrow($color);
}

.info-tooltip-implement {
  $color: variables.$implement-tooltip-color;
  @include info-tooltip($color);
  @include direction-arrow($color);
}

.info-tooltip-measure {
  $color: variables.$measure-tooltip-color;
  @include info-tooltip($color);
  @include direction-arrow($color);
}

.info-tooltip-report {
  $color: variables.$report-tooltip-color;
  @include info-tooltip($color);
  @include direction-arrow($color);
}
